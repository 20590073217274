import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { parseLocaleNumber } from '../+authenticated/shared/locale/locale.helper';

@Pipe({
  name: 'decimal',
  pure: true,
  standalone: true,
})
export class DecimalPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  public transform(
    value: string | number,
    precision: number | 'compact' = 2,
    emptyVal: false | string = false,
    type: 'decimal' | 'percent' = 'decimal'
  ): string {
    let number = parseFloat(`${value}`);

    if (isNaN(number)) {
      return emptyVal ? emptyVal : '';
    }

    const options = {
      style: type,
      maximumFractionDigits: precision === 'compact' ? 2 : precision,
    };

    if (precision !== 'compact') {
      options['minimumFractionDigits'] = precision;
    }

    if (type === 'percent') {
      number = number / 100;
    }

    return new Intl.NumberFormat(this.translate.currentLang, options).format(number);
  }

  public parse(value: string, precision: number): any {
    const options = {
      style: 'decimal',
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    };
    return parseLocaleNumber(value, this.translate.currentLang, options);
  }
}
